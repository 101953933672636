import React, { cloneElement, Fragment, useEffect, useState } from "react";
import {
  Home,
  People,
  Settings,
  Logout,
  ExpandLess,
  ExpandMore,
  Dashboard,
  Message,
  Commute,
  AccountCircle,
  EventNote,
  PeopleAlt,
  Euro,
  AssignmentTurnedIn,
  MoneyOff,
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Box, useMediaQuery, SwipeableDrawer } from "@mui/material";
import Header from "./Header/Header";
import { Gear } from "@phosphor-icons/react";

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  padding: theme.spacing(1, 0, 1, 1),
  backgroundColor: "#fff",
  color: "#2596be",
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: 300,
  flexShrink: 0,
  zIndex: theme.zIndex.drawer + 1,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Navbar = (props) => {
  const { user } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Detect if it's mobile or tablet
  const [open, setOpen] = useState(!isMobile);
  const [openSections, setOpenSections] = useState({});
  const [menuItems, setMenuItems] = useState([]);
  const [mobileOpen, setMobileOpen] = useState(false); // State for SwipeableDrawer

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const handleToggle = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const logout = () => {
    fetch(`/api/auth/logout`).then((res) => {
      navigate("/login");
    });
  };

  const adminItems = [
    { text: "Home", icon: <Home />, path: "/" },
    {
      text: "Requests",
      icon: <Message />,
      subItems: [
        { text: "List", path: "/requests/List", icon: <Dashboard /> },
        { text: "New Request", path: "/requests/New", icon: <EventNote /> },
      ],
    },
    { text: "Customers", icon: <People />, path: "/customers" },
    {
      text: "Tasks",
      icon: <AssignmentTurnedIn />,
      path: "/task",
    },
    {
      text: "Uber",
      icon: <Commute />,
      path: "/uber",
    },
    { text: "Invoices", icon: <Euro />, path: "/invoices" },
    { text: "Payroll", icon: <MoneyOff />, path: "/payroll" },
  ];

  const uberItems = [
    { text: "Home", icon: <Home />, path: "/" },
    {
      text: "Tasks",
      icon: <AssignmentTurnedIn />,
      path: "/task",
    },
    {
      text: "Uber",
      icon: <Commute />,
      path: "/uber",
    },
    { text: "Customers", icon: <People />, path: "/customers" },
  ];

  useEffect(() => {
    if (user) {
      if (user.Role.includes("Admin")) {
        setMenuItems(adminItems);
      } else {
        setMenuItems(uberItems);
      }
    }
  }, [user]);

  const drawerContent = (
    <Box>
      <DrawerHeader>
        <Box></Box>
        <IconButton
          onClick={isMobile ? () => setMobileOpen(false) : handleDrawerClose}
        >
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List sx={{ overflow: "hidden" }}>
        {menuItems.map((item) => (
          <Fragment key={item.text}>
            {item.subItems ? (
              <>
                <ListItem button onClick={() => handleToggle(item.text)}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText
                    sx={{
                      color: location.pathname.includes(item.path)
                        ? "primary"
                        : "textSecondary",
                      fontWeight: location.pathname.includes(item.path)
                        ? "bold"
                        : "normal",
                    }}
                    primary={item.text}
                  />
                  {openSections[item.text] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                {openSections[item.text] && (
                  <List component="div" disablePadding>
                    {item.subItems.map((subItem) => (
                      <ListItem
                        key={subItem.text}
                        button
                        onClick={() => navigate(subItem.path)}
                        selected={location.pathname === subItem.path}
                        sx={{
                          pl: 4,
                          typography: "body2",
                          fontStyle: "italic",
                          bgcolor:
                            location.pathname === subItem.path
                              ? "action.selected"
                              : "transparent",
                        }}
                      >
                        <ListItemIcon>{subItem.icon}</ListItemIcon>
                        <ListItemText
                          primary={subItem.text}
                          sx={{
                            color:
                              location.pathname === subItem.path
                                ? "primary"
                                : "textSecondary",
                            fontWeight:
                              location.pathname === subItem.path
                                ? "bold"
                                : "normal",
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
              </>
            ) : (
              <ListItem
                key={item.text}
                button
                onClick={() => navigate(item.path)}
                selected={
                  location.pathname.includes(item.path) ||
                  (item.path === "/" && location.pathname === "/")
                }
                sx={{
                  bgcolor:
                    location.pathname.includes(item.path) ||
                    (item.path === "/" && location.pathname === "/")
                      ? "action.selected"
                      : "transparent",
                }}
              >
                <ListItemIcon>
                  {cloneElement(item.icon, {
                    color:
                      location.pathname === item.path ||
                      (item.path === "/" && location.pathname === "/")
                        ? "primary"
                        : "textSecondary",
                  })}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  sx={{
                    color:
                      location.pathname === item.path ||
                      (item.path === "/" && location.pathname === "/")
                        ? "primary"
                        : "textSecondary",
                    fontWeight:
                      location.pathname === item.path ||
                      (item.path === "/" && location.pathname === "/")
                        ? "bold"
                        : "normal",
                  }}
                />
              </ListItem>
            )}
          </Fragment>
        ))}
      </List>
      <Divider />
      <List>
        {user && user.Role.includes("Admin") && (
          <ListItem button onClick={() => navigate("/users")}>
            <ListItemIcon>
              <PeopleAlt />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
        )}
        <ListItem button onClick={() => navigate("/settings")}>
          <ListItemIcon>
            <Gear size={24} />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
        <ListItem button onClick={logout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Header
            setUser={props.setUser}
            handleDrawerOpen={
              isMobile ? () => setMobileOpen(true) : handleDrawerOpen
            }
            open={open}
          />
        </Toolbar>
      </AppBar>

      {isMobile ? (
        <SwipeableDrawer
          sx={{ zIndex: 9999 }}
          open={mobileOpen}
          onClose={() => setMobileOpen(false)}
          onOpen={() => setMobileOpen(true)}
        >
          {drawerContent}
        </SwipeableDrawer>
      ) : (
        <Drawer variant="permanent" open={open}>
          {drawerContent}
        </Drawer>
      )}
    </Box>
  );
};

export default Navbar;
