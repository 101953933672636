import React, { useState, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid2,
  Typography,
  Alert,
} from "@mui/material";

const UploadFileDialog = ({ open, onClose, category }) => {
  const [formData, setFormData] = useState({
    Name: "",
    Phone: "",
    Email: "",
    DOB: "",
    MPESA: "",
    Category: category,
  });
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const fileInput1 = useRef();
  const fileInput2 = useRef();
  const fileInput3 = useRef();

  // Validation functions for phone and email
  const isValidPhone = (phone) => {
    const phoneRegex = /^0\d{9}$/;
    return phoneRegex.test(phone);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setMsg("");

    // Check if phone number is valid
    if (!isValidPhone(formData.Phone)) {
      setMsg("Invalid phone number! It must be 10 digits and start with 0.");
      setLoading(false);
      return;
    }

    // Check if email is valid
    if (!isValidEmail(formData.Email)) {
      setMsg("Invalid email format!");
      setLoading(false);
      return;
    }

    // Ensure all required fields are filled, including file inputs
    if (
      !formData.Name ||
      !formData.Phone ||
      !formData.Email ||
      !formData.DOB ||
      !fileInput1.current?.files[0] || // National ID
      !fileInput2.current?.files[0] || // Passport
      !fileInput3.current?.files[0] // Police Clearance
    ) {
      setMsg("All fields are required!");
      setLoading(false);
      return;
    }

    // Create FormData to handle file upload
    const data = new FormData();
    data.append("Name", formData.Name);
    data.append("Phone", formData.Phone);
    data.append("Email", formData.Email);
    data.append("DOB", formData.DOB || null);
    data.append("MPESA", formData.MPESA || null);
    data.append("Category", formData.Category || null);

    // Attach files
    data.append("NationalID", fileInput1.current.files[0]);
    data.append("Passport", fileInput2.current.files[0]);
    data.append("Police", fileInput3.current.files[0]);

    // Submit the data via POST request to /api/uber/createfromuser
    fetch("/api/uber/createfromuser", {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        if (result.success) {
          setMsg("User and Uber document created successfully!");
          onClose(); // Close the dialog on success
        } else {
          setMsg(result.error || "Something went wrong!");
        }
      })
      .catch((error) => {
        setLoading(false);
        setMsg("Error: " + error.message);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Create New</DialogTitle>
      <DialogContent>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 12 }}>
              <TextField
                fullWidth
                label="Name"
                value={formData.Name}
                onChange={(e) =>
                  setFormData({ ...formData, Name: e.target.value })
                }
                required
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                fullWidth
                label="Phone Number"
                value={formData.Phone}
                onChange={(e) =>
                  setFormData({ ...formData, Phone: e.target.value })
                }
                required
              />
            </Grid2>

            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                fullWidth
                label="Email"
                value={formData.Email}
                onChange={(e) =>
                  setFormData({ ...formData, Email: e.target.value })
                }
                required
              />
            </Grid2>

            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                fullWidth
                label="Date of Birth"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={formData.DOB}
                onChange={(e) =>
                  setFormData({ ...formData, DOB: e.target.value })
                }
                required
              />
            </Grid2>

            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                fullWidth
                label="MPESA Code"
                value={formData.MPESA}
                onChange={(e) =>
                  setFormData({ ...formData, MPESA: e.target.value })
                }
              />
            </Grid2>

            {/* File Inputs Styled as TextFields */}
            <Grid2 size={{ xs: 12, md: 4 }}>
              <Typography>National ID *</Typography>
              <input
                ref={fileInput1}
                type="file"
                accept=".pdf,.png,.jpg,.jpeg"
                style={{
                  display: "block",
                  padding: "16.5px 14px",
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                  borderRadius: "4px",
                  width: "100%",
                }}
              />
            </Grid2>

            <Grid2 size={{ xs: 12, md: 4 }}>
              <Typography>Passport Photo *</Typography>
              <input
                ref={fileInput2}
                type="file"
                accept=".pdf,.png,.jpg,.jpeg"
                style={{
                  display: "block",
                  padding: "16.5px 14px",
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                  borderRadius: "4px",
                  width: "100%",
                }}
              />
            </Grid2>

            <Grid2 size={{ xs: 12, md: 4 }}>
              <Typography>Police Clearance *</Typography>
              <input
                ref={fileInput3}
                type="file"
                accept=".pdf,.png,.jpg,.jpeg"
                style={{
                  display: "block",
                  padding: "16.5px 14px",
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                  borderRadius: "4px",
                  width: "100%",
                }}
              />
            </Grid2>
          </Grid2>
          {msg && <Alert color="warning">{msg}</Alert>}
        </form>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          type="submit"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "Submitting..." : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadFileDialog;
