import { useEffect, useState } from "react";
import GaugeChart from "react-gauge-chart";
import {
  Box,
  Typography,
  IconButton,
  Grid2,
  Divider,
  Card,
  Button,
} from "@mui/material";
import AddTaskIcon from "@mui/icons-material/AddTask";
import CustomBarChart from "../Stats/CustomBarChart";
import MyError from "../Util/MyError";
import Pagination from "../Util/Pagination";
import WaveLoading from "../Util/WaveLoading";
import UberBody from "./UberBody";
import { Create } from "@mui/icons-material";
import CreateTask from "./CreateTask";

export default function Uber(props) {
  var jwt = require("jsonwebtoken");
  const [data, setData] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showing, setShowing] = useState(true);
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState("Active Tasks");
  const [refresh, setRefresh] = useState(false);
  const [offset, setOffset] = useState(0);
  const [id, setId] = useState(null);
  const [user, setUser] = useState("");
  const [type, setType] = useState("driver");

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
    if (props.url.includes("Driver")) {
      setType("driver");
    } else {
      setType("rider");
    }
  }, []);

  useEffect(() => {
    if (props.url) {
      fetch(`/api/uber/stats/top/${props.url}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setData(data);
        })
        .catch((e) => {});
    }
  }, [props.url, refresh]);

  useEffect(() => {
    const token = localStorage.getItem("gfgfgggn");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        if (Date.now() >= decoded.exp * 1000) {
          window.location.href = "/login";
        } else {
          setId(decoded.UserID);
          setUser(decoded.Name);
        }
      } catch (error) {
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }
  }, []);

  useEffect(() => {
    if (!id) return;
    setTasks(null);
    if (active === "Active Tasks") {
      setLoading(true);

      fetch(`/api/uber/admpaginated/Received/${props.url}/${offset * 12}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch messages!!!");
          }
          return res.json();
        })
        .then((data) => {
          setLoading(false);
          setTasks(data);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else if (active === "On Hold") {
      setLoading(true);
      fetch(`/api/uber/admpaginated/On Hold/${props.url}/${offset * 12}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch messages!!!");
          }
          return res.json();
        })
        .then((data) => {
          setLoading(false);
          setTasks(data);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      fetch(`/api/uber/admpaginated/Complete/${props.url}/${offset * 12}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch messages!!!");
          }
          return res.json();
        })
        .then((data) => {
          setLoading(false);
          setTasks(data);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [offset, active, refresh, id, props.url]);

  const Item = ({ txt }) => (
    <Box
      onClick={() => props.setSelected(txt)}
      sx={{
        cursor: "pointer",
        display: "grid",
        gridTemplateColumns: "10px 1fr",
        gap: "10px",
        "&:hover": {
          h3: {
            color: "primary.main",
          },
          div: {
            backgroundColor: "secondary.main",
          },
        },
        ...(txt === props.active && {
          h3: {
            color: "primary.main",
          },
          div: {
            backgroundColor: "secondary.main",
          },
        }),
      }}
    >
      <Box sx={{ width: "10px", height: "100%" }} />
      <Typography variant="h6" sx={{ fontWeight: 560, fontSize: "small" }}>
        {txt}
      </Typography>
    </Box>
  );

  return (
    <Box sx={{ position: "relative" }}>
      {/* Task Stats Section */}
      <Grid2 container spacing={3}>
        <Grid2 size={{ md: 9, xs: 12, sm: 6 }}>
          <Box
            className="bar"
            sx={{ display: "flex", alignItems: "center", gap: 2 }}
          >
            <Box
              sx={{ width: "10px", height: "24px", backgroundColor: "orange" }}
            />
            <Typography variant="h6">{props.url} Tasks</Typography>
          </Box>
        </Grid2>
        <Grid2 size={{ md: 3, xs: 12, sm: 6 }}>
          <Box
            className="bar"
            sx={{ display: "flex", gap: 2, alignItems: "center" }}
          >
            <Box
              sx={{ width: "10px", height: "24px", backgroundColor: "orange" }}
            />
            <Typography variant="h6">Performance</Typography>
          </Box>
        </Grid2>
        <Grid2 size={{ md: 4.5, xs: 12 }}>
          <Box
            component={Card}
            sx={{
              p: 2,
              boxShadow: "0px 8px 16px #60606040",
              borderRadius: "8px",
              height: "100%",
              gap: 2,
            }}
          >
            <div className="section">
              <Typography>Monthly Performance</Typography>
              <Divider sx={{ my: 1 }} />
              <CustomBarChart
                aspect={1.8}
                data={data ? data.Bar : null}
                color="orange"
              />
            </div>
          </Box>
        </Grid2>
        <Grid2 size={{ md: 4.5, xs: 12 }}>
          <Box
            component={Card}
            sx={{
              p: 2,
              boxShadow: "0px 8px 16px #60606040",
              borderRadius: "8px",
              height: "100%",
              gap: 2,
            }}
          >
            <Typography>Completion Rate</Typography>
            <Divider sx={{ my: 1 }} />
            <GaugeChart
              style={{ marginTop: "1em" }}
              className="gg"
              id="gauge-chart2"
              nrOfLevels={3}
              colors={["red", "orange", "green"]}
              arcWidth={0.3}
              percent={data ? (data?.Complete / data?.AllTasks).toFixed(2) : 0}
              textColor="gray"
            />
          </Box>
        </Grid2>

        <Grid2 size={{ md: 3, xs: 12 }}>
          <Box
            component={Card}
            className="single"
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              p: 2,
              boxShadow: "0px 8px 16px #60606040",
              borderRadius: "8px",
              mb: 2,
            }}
          >
            <AddTaskIcon
              className="ts"
              sx={{ fontSize: "44px", color: "primary.main" }}
            />
            <Box ml={"auto"}>
              <Typography textAlign="end" variant="h5">
                {data ? data?.AllTasks : 0}
              </Typography>
              <Typography textAlign="end" variant="body2">
                Completed: <b>{data ? data?.Complete : 0}</b>
              </Typography>
            </Box>
          </Box>
          <Box
            component={Card}
            sx={{
              p: 2,
              boxShadow: "0px 8px 16px #60606040",
              borderRadius: "8px",

              gap: 2,
            }}
          >
            <Box className="hl" display="block">
              <Typography textAlign="center" color="primary" variant="h6">
                {data ? data?.Overdue : 0}
              </Typography>
              <Typography textAlign="center" variant="body1">
                Overdue
              </Typography>
            </Box>
            <Divider sx={{ my: 1 }} />
            <Box
              className="div1auto"
              sx={{ display: "flex", alignItems: "center", gap: 1 }}
            >
              <Box
                sx={{
                  width: "10px",
                  height: "16px",
                  backgroundColor: "green",
                  my: 1,
                }}
              />
              <Typography fontSize="small">1 Day: </Typography>
              <Typography fontSize="small">{data ? data?.Day1 : 0}</Typography>
            </Box>
            <Box
              className="div1auto"
              sx={{ display: "flex", alignItems: "center", gap: 1 }}
            >
              <Box
                sx={{
                  width: "10px",
                  height: "16px",
                  backgroundColor: "orange",
                  my: 1,
                }}
              />
              <Typography fontSize="small">2 Days: </Typography>
              <Typography fontSize="small">{data ? data?.Day2 : 0}</Typography>
            </Box>
            <Box
              className="div1auto"
              sx={{ display: "flex", gap: 1, alignItems: "center" }}
            >
              <Box
                sx={{
                  width: "10px",
                  height: "16px",
                  backgroundColor: "red",
                  my: 1,
                }}
              />
              <Typography fontSize="small">3 or More Days: </Typography>
              <Typography fontSize="small">{data ? data?.Day3 : 0}</Typography>
            </Box>
          </Box>
        </Grid2>
      </Grid2>

      {/* Task Selector */}
      <Box className="selector" sx={{ marginY: 3 }}>
        <Box
          className="cont"
          sx={{
            display: "flex",
            gap: "2em",
          }}
        >
          <Selector
            txt="Active Tasks"
            active={active}
            setActive={setActive}
            setOffset={setOffset}
          />
          {/* <Selector
            txt="On Hold"
            active={active}
            setActive={setActive}
            setOffset={setOffset}
          /> */}
          <Selector
            txt="Completed Tasks"
            active={active}
            setActive={setActive}
            setOffset={setOffset}
          />
          <Box flexGrow={1}></Box>
          <Button
            onClick={() => {
              setOpen(true);
            }}
            variant="contained"
          >
            New
          </Button>
        </Box>
      </Box>

      {/* Task Content */}
      <Box className="tcontent" sx={{ marginY: 3 }}>
        {tasks && tasks?.data?.length > 0 ? (
          tasks?.data?.map((item, index) => (
            <UberBody
              item={item}
              key={index}
              task={true}
              refresh={refresh}
              setRefresh={setRefresh}
              type={type}
              date={item.createdAt.split("T")[0]}
              file={item.NationalID}
            />
          ))
        ) : (
          <MyError txt="No Active Tasks" />
        )}
        {tasks && tasks?.data?.length > 0 && (
          <Pagination
            totalPages={Math.ceil(tasks.total / 12)}
            currentPage={offset}
            handlePageChange={(v) => setOffset(v)}
          />
        )}
      </Box>

      <CreateTask
        open={open}
        onClose={() => {
          setOpen(false);
          setRefresh(!refresh);
        }}
        category={props.url}
      />

      {loading && <WaveLoading />}
    </Box>
  );
}

const Selector = (props) => (
  <Box
    className={props.active === props.txt ? "active" : "item"}
    sx={{
      cursor: "pointer",
      display: "flex",
      gridTemplateColumns: "10px 1fr",
      gap: "10px",
      "&:hover": {
        h3: {
          color: "primary.main",
        },
        div: {
          backgroundColor: "secondary.main",
        },
      },
      ...(props.active === props.txt && {
        h3: {
          color: "primary.main",
        },
        div: {
          backgroundColor: "secondary.main",
        },
      }),
    }}
    onClick={() => {
      props.setActive(props.txt);
      props.setOffset(0);
    }}
  >
    <Box sx={{ width: "10px", height: "100%" }} />
    <Typography sx={{ my: "auto" }} variant="title">
      {props.txt}
    </Typography>
  </Box>
);
