import { useEffect, useState } from "react";
import { Typography, Box, Divider, IconButton, Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import QueryBuilder from "./QueryBuilder";
import Uber from "./Uber";
import UberData from "./UberData";

export default function UberHome(props) {
  const [active, setActive] = useState("New Drivers");
  var jwt = require("jsonwebtoken");
  const [user, setUser] = useState("");
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("gfgfgggn");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        if (Date.now() >= decoded.exp * 1000) {
          window.location.href = "/login";
        } else {
          setUser(decoded.Name);
        }
      } catch (error) {
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }
  }, []);

  const Item = ({ txt }) => (
    <Box
      onClick={() => setActive(txt)}
      sx={{
        cursor: "pointer",
        display: "grid",
        gridTemplateColumns: "10px 1fr",
        gap: "10px",
        "&:hover": {
          h3: {
            color: "primary.main",
          },
          div: {
            backgroundColor: "secondary.main",
          },
        },
        ...(txt === active && {
          h3: {
            color: "primary.main",
          },
          div: {
            backgroundColor: "secondary.main",
          },
        }),
      }}
    >
      <Box sx={{ width: "10px", height: "100%" }} />
      <Typography p={1} variant="body1" sx={{ fontWeight: 560 }}>
        {txt}
      </Typography>
    </Box>
  );

  return (
    <Box sx={{ position: "relative" }}>
      {/* Welcome Section */}
      <Box
        sx={{
          mt: 1,
          display: "grid",
          gridTemplateColumns: { xs: "1fr", md: "1fr auto" },
          gap: "1em",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography variant="h6" color="primary">
            Welcome to the UBER MODULE
          </Typography>
          <Typography display="flex" alignItems="center" variant="body1">
            Let's do our best today <EmojiEmotionsIcon color="warning" />
          </Typography>
        </Box>
        <IconButton
          sx={{
            width: "40px",
            height: "40px",
            color: "primary.main",
            backgroundColor: "white",
            borderRadius: "5px",
            "&:hover": {
              color: "primary.dark",
            },
          }}
          onClick={() => setRefresh(!refresh)}
        >
          <RefreshIcon />
        </IconButton>
      </Box>

      {/* Navigation Items */}
      <Box sx={{ backgroundColor: "white", my: 2 }}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(5, auto)",
            gap: "2em",
            width: "fit-content",
          }}
        >
          <Item txt="New Drivers" />
          <Item txt="Driver Renewals" />
          <Item txt="New Riders" />
          <Item txt="Rider Renewals" />
          <Item txt="Data" />
        </Box>
      </Box>

      {/* Conditional Render based on Active Item */}
      {active === "New Drivers" && (
        <Uber url="New Driver" setSelected={setActive} active={active} />
      )}
      {active === "Driver Renewals" && (
        <Uber url="Driver Renewal" setSelected={setActive} active={active} />
      )}
      {active === "New Riders" && (
        <Uber url="New Rider" setSelected={setActive} active={active} />
      )}
      {active === "Rider Renewals" && (
        <Uber url="Rider Renewal" setSelected={setActive} active={active} />
      )}
      {active === "Data" && (
        <UberData setSelected={setActive} active={active} />
      )}
    </Box>
  );
}
